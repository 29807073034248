import { defaultStyleOptions, StyleOptions } from 'botframework-webchat-api';
import FullBundleStyleOptions from 'botframework-webchat/lib/types/FullBundleStyleOptions';
import { DirectLine } from 'botframework-directlinejs';
import createDefaultAdaptiveCardHostConfig from 'botframework-webchat/lib/adaptiveCards/Styles/adaptiveCardHostConfig'
import Composer, { ComposerProps } from 'botframework-webchat-component/lib/Composer';
import LocalizedStrings from 'botframework-webchat-api/lib/types/LocalizedStrings'
import { DruidAction } from './hooks/useWebChatReducer';
import { randomHash } from './utils/randomHash';
import { druidCreateBlobUrl } from './utils/urlUtils';
import { BotLanguage, WebChatHeaderOptions, WebChatState } from './WebChatContext';
import { typingAnimation } from '../resources/images/druidAnimations'
import druidDefaultLocalization from './logics/defaultDruidLocalization.json';
import * as merge from 'deepmerge';
import createStyleSet from 'botframework-webchat/lib/createFullStyleSet';

export type BotFrameworkOptions = Omit<ComposerProps, "directLine" | "userID">;
export type BotFrameworkMiddlewares = Pick<ComposerProps, "activityMiddleware" | "activityStatusMiddleware" | "attachmentForScreenReaderMiddleware"| "attachmentMiddleware" 
                                                        | "avatarMiddleware" | "cardActionMiddleware"| "groupActivitiesMiddleware" | "scrollToEndButtonMiddleware" | "toastMiddleware"  
                                                        | "typingIndicatorMiddleware">;

export type BotFrameworkStyleOptions =  Pick<BotFrameworkOptions, "styleOptions">;

export enum BotFrameworkDefaultStyleOptions { // useful when accent, for ex, would be changed here. not a replacement of defaultOptions and not a replacement of $propValue
    accent = '#70b244',
    secondaryBackgroundColor = '#f4f5f7',
    rootFontSize= '1rem'
}

export type DruidIconType = null | string | (({size}: {size: any}) => string);

export type WebChatEventHandlerType = ((action: DruidAction, currentDruidState: WebChatState, currentBFState?: any) => any);
// export type WebChatTransformLoadConfigurationResultType = ((loadConfiguration: WebChatOptions & { middlewares: any }) => WebChatOptions & { middlewares: any });


export type DruidUIOptions = {
    autoExpandEnabled?: boolean,
    containerElement?: Nullable<HTMLElement> | Function,
    closeButtonEnabled?: Nullable<boolean>,
    height?: Nullable<string | number>,
    width?: Nullable<string | number>,
    minWidth?: Nullable<string | number>,
    chatTitle?: string,
    parentElement?: Nullable<HTMLElement>,
    contextMenuIcon?: string, // unused. from v1
    position?: 'left' | 'right', // same type as state.side
    showUploadButton?: Nullable<boolean>, // v1 specific. same functionality as styleOptions.hideUploadButton
    floatRight?: Nullable<number>,
    accessibilityFeaturesEnabled?: Nullable<boolean>,
} & Partial<{ // v2 UI options
    bottom?: Nullable<string | number>,
    top?: Nullable<string | number>,
    resizableEnabled?: Nullable<boolean>,
    draggableEnabled?: Nullable<boolean>,
    fullScreenMode: Nullable<boolean>, // used along with isMobile to determine if snippet should be in fullscreen. also supresses the header onclick collapse.
    restylingsEnabled: boolean,

    showPoweredBy: Nullable<boolean>,
    activityWrapperClassEnabled: Nullable<boolean>,
    isVoiceInput: Nullable<boolean>,
    v2_chatTitle_icon: string,
    v2_minimize_icon: DruidIconType,
    v2_contextMenu_icon: DruidIconType,
    v2_containerElement_expand_icon: DruidIconType,
    v2_containerElement_collapse_icon: DruidIconType,
    header_customElement: string,

    v2_switch_icon: string,
    v2_customButton_toggled_icon: string,
    v2_customButton_off_icon: string,
    v2_custom_button_initial_toggled?: boolean, // undefined not to render button. true/false to set button's state

    hideDruidSendBox?: Nullable<boolean>,
    hideSuggestedActions?: Nullable<boolean>,
    blockingInputDruidSendBox?: Nullable<boolean>, 
    headerOptions?: Nullable<Partial<WebChatHeaderOptions>>,

    druidSendBoxPaddingLeftRight: Nullable<number>,
    druidSendBoxPaddingTop: Nullable<number>, //outer padding of sendBox
    druidSendBoxPaddingBottom: Nullable<number>,
    druidSendBoxTextBoxPadding: Nullable<number> //inner padding of sendBox (textbox padding actually). adds height to sendBox
    druidSendBoxInnerPaddingLeftRight: Nullable<number> //inner leftright padding of sendBox
    druidSendBoxInnerPaddingTopBottom: Nullable<number>, //inner topbottom padding of sendBox
    druidSendBoxInnerBorderRadius: Nullable<number>,
    

    buttonsPadding: Nullable<number>,
    buttonsTextColor: Nullable<string>,
    buttonsBorderRadius: Nullable<number>,
    buttonsBorderColor: Nullable<string>,
    buttonsBackgroundColor: Nullable<string>,
    buttonsBackgroundColorOnHover: Nullable<string>,
    buttonsTextColorOnHover: Nullable<string>,
    buttonsBorderColorOnHover: Nullable<string>,

    activityStatusReadColor: Nullable<string>,
    activityStatusBotName: Nullable<string>,
    activityStatusUserName: Nullable<string>,
    activityStatusShowBotName: Nullable<boolean>,
    activityStatusShowUserName: Nullable<boolean>,

    chatBoxBorderRadius: Nullable<number>,
    chatBoxBottomRadius: Nullable<number>,
    chatBoxBorderColor: Nullable<string>,
    chatBoxBorderSize: Nullable<number>,

    headerTextColor: Nullable<string>,
    headerTextColorOnHover: Nullable<string>,
    headerButtonColorOnFocus: Nullable<string>,

    headerFontSize: Nullable<number | string>,
    headerBottomBorderRadius: Nullable<number | string>,
    headerBorderRadius: Nullable<number | string>,
    headerOuterPadding: Nullable<number | string>,
    headerBackground: Nullable<string>,
    
    transcriptFontSize: Nullable<string | number>,
    transcriptLinkColor: Nullable<string>,
    sendBoxFontSize: Nullable<string | number>,
    lineHeight: Nullable<string | number>,
    rootFontSize: Nullable<string | number>,

    disableTyping: Nullable<boolean>,
    disableTypingAnimation: Nullable<boolean>,

    customTimestampFormat: string // https://momentjs.com/docs/#/displaying/,
    showTypingName: Nullable<boolean>,
}>;
export type WebChatOptions = BotFrameworkOptions //& Partial<FullComposerProps>
    & {
        instanceID: string,
        botConnection: DirectLine | null,
    }
    & Partial<{
        botId: Nullable<string>,
        baseUrl: Nullable<string>,
        apcUrl: Nullable<string>,
        queryParams: Nullable<string>,
        authorizationUrl: Nullable<string>,
        authorizationBaseUrl: Nullable<string>,
        userId: string // should we remove ?
        username: string // should we remove ? this is not typo error. "username" in bf4
        customAuthHeaders?: any,

        /* cookies */
        druidCookieConsentEnabled: Nullable<boolean>,
        conversationTokenEnabled: Nullable<boolean>,
        conversationToken_namePrefix: Nullable<string>,
        druidWebChatIsOpenedEnabled: Nullable<boolean>,
        druidWebChatIsOpened_namePrefix: Nullable<string>, 
        cookieDomain: Nullable<string>,  // druidConversationTokenDomain
        druidCookiesExpirationInDays: number,

        notificationsEnabled: Nullable<boolean>,
        isDisabled: boolean, // remote disable whole chat (hide)
        withCredentials: Nullable<boolean>,
        withCredentials_authorization: Nullable<boolean>, //override for 'withCredentials' -> specific for authorizationCall
        isWidget?: boolean,
        isMultichat?: boolean,
        backgroundTrafficEnabled?: boolean,
        heartBeatUrl: Nullable<string>,
        otherServicesEndpointIndex: number,
        _isSecondaryChannel?: boolean,
        _disableAutoForwardCurrentDocumentQueryString?: boolean,
        flowId?: Nullable<string>,
        isFormChannel?: boolean,
        UI?: DruidUIOptions,
    }> 
    & Partial<{ 
        middlewares?: BotFrameworkMiddlewares, // you can provide string remote, or function localy
        //  activityMiddleware?: any // should we remove ? -> should not because it is oneormany<T> on ComposerProps
    }>
    & Partial<{ // specific for fullscreen webchat
        conversationHistoryActivities: any[], // will be used
        authorizationResultDto?: any
    }>
    & Partial<{ // v2 specific
        languages: BotLanguage[],
        adaptiveCardsHostConfig: any,
        searchAssistEnabled: boolean,
        wideOptions: {
            UI?: DruidUIOptions
        } & BotFrameworkStyleOptions,
        designPatterns: {
            nonCarousel_HeroCard_AutoWidth: boolean,
            nonCarousel_HeroCard_ButtonsOnly_BackgroundTransparent: boolean,
        },
        customDruidStyleSet?: Partial<ReturnType<typeof createStyleSet>> & { webchatRoot: any }
        webChatEventHandler?: WebChatEventHandlerType,
        // transformLoadConfigurationResult?: WebChatTransformLoadConfigurationResultType,
        localization?: { [language: string]: LocalizedStrings },
        _loadConfigurationJSON?: any,
        _requestNewConversationToken?: boolean, // used to request new conv token when using authenticated conversations and changing the main conversation is business required (AuthorizeAsync call)
        onLoadHandlerPromise?: (resolve: boolean) => void;
    }>;

export const overridenBFStyleOptions: FullBundleStyleOptions = druidNormalizeStyleOptions({  // these are the default druid options override for bf4 webchat
    // groupTimestamp: 0, //to remove

    accent: BotFrameworkDefaultStyleOptions.accent,
    paddingRegular: 20,
    primaryFont: '"Druid Regular", "Druid Twemoji"',

    backgroundColor: 'rgba(255, 255, 255, 1)',

    botAvatarBackgroundColor: 'Transparent',
    userAvatarBackgroundColor: 'Transparent',


    hideSendBox: true,
    sendBoxButtonColor: '$subtle',
    sendBoxDisabledTextColor: '$subtle',
    sendBoxPlaceholderColor: '$subtle',
    sendBoxButtonColorOnDisabled: '#CCC',
    sendBoxButtonColorOnFocus: '#333',
    sendBoxButtonColorOnHover: '#333',
    sendBoxBackground: BotFrameworkDefaultStyleOptions.secondaryBackgroundColor,
    sendBoxBorderTop: 0,
    sendBoxTextWrap: true,
    sendBoxMaxHeight: 100, // used for text are maxHeight
    emojiSet:  {
        ':)': '😊',
        ':-)': '😊',
        '(:': '😊',
        '(-:': '😊',
        ':-|': '😐',
        ':|': '😐',
        ':-(': '☹️',
        ':(': '☹️',
        ':-D': '😀',
        ':D': '😀',
        ':-p': '😛',
        ':p': '😛',
        ':-P': '😛',
        ':P': '😛',
        ':-o': '😲',
        ':o': '😲',
        ':O': '😲',
        ':-O': '😲',
        ';-)': '😉',
        ';)': '😉',
        '<3': '❤️',
        '</3': '💔',
        '<\\3': '💔'
    }, 

    typingAnimationHeight: 40,
    // has to be like this: 'url(https://site.com/typing.gif)'
    typingAnimationBackgroundImage: druidCreateBlobUrl(typingAnimation, 'image/gif'),

    //  autoScrollSnapOnActivity: 1, // must be set to 1 or else error when a choice is first message
    autoScrollSnapOnPage: 90,
    autoScrollSnapOnActivityOffset: 100,
    scrollToEndButtonBehavior: 'any',
    
    adaptiveCardsParserMaxVersion: '1.5',

    // bubbleMaxWidth: '100%', // removed. because when width is large, bubbles are also large.
    bubbleFromUserBackground: '#d3d8e3',
    bubbleBackground: BotFrameworkDefaultStyleOptions.secondaryBackgroundColor,
    bubbleBorderColor: '#e4e4e4',
    messageActivityWordBreak: 'break-word',
    bubbleNubSize: 0,
    bubbleNubOffset: 'top',
    bubbleFromUserNubSize: 0,
    bubbleFromUserNubOffset: 'top',
    bubbleBorderRadius: 20,
    bubbleFromUserBorderRadius: 20,
    bubbleImageHeight: 240, // was 'auto' but images are to large

    transcriptTerminatorBackgroundColor: 'Transparent', // to hide transcript terminator
    transcriptTerminatorColor: 'Transparent',
    transcriptTerminatorFontSize: 0,

    transcriptVisualKeyboardIndicatorColor: '$accent',
    transcriptVisualKeyboardIndicatorStyle: 'solid', // this is default, not an actual override
    transcriptVisualKeyboardIndicatorWidth: 0,

    transcriptActivityVisualKeyboardIndicatorColor: '$accent',
    transcriptActivityVisualKeyboardIndicatorStyle: 'dashed',
    transcriptActivityVisualKeyboardIndicatorWidth: 1,
    

    toasterSingularMaxHeight: 150,

    suggestedActionsVisualKeyboardIndicatorColor: 'Transparent',
    suggestedActionBorderRadius: 10, // if not provided, it is inherited from UI.buttonsBorderRadius
    suggestedActionBorderColor: '$buttonsBorderColor',
    suggestedActionTextColor: '$buttonsTextColor', // must be equal to UI.buttonsTextColor
    suggestedActionBackground: '$buttonsBackgroundColor',// must be equal to UI.buttonsBackgroundColor
    suggestedActionBackgroundColor: '$buttonsBackgroundColor',// must be equal to UI.buttonsBackgroundColor

    // suggestedActionB`    `backgroundColor: 'white', // default from bf4
    suggestedActionDisabledBackground: '#F0F0F0', // in sync with BF hardcoded adaptivecards .ac-pushButton disable color
    suggestedActionDisabledBorderColor: '#A9A9A9', // in sync with BF hardcoded adaptivecards .ac-pushButton disable color
    suggestedActionDisabledTextColor: '#6D6D6D', // in sync with BF hardcoded adaptivecards .ac-pushButton disable color

    suggestedActionLayout: 'flow', // override of carousel,

    timestampColor: '$subtle'
    
});
// @ts-ignore
const druidDefaultStyleOptions: FullBundleStyleOptions = {
    ...defaultStyleOptions, // just to have the default options whatever the context
    ...overridenBFStyleOptions
}


const defaultAdaptiveCardHostConfig = createDefaultAdaptiveCardHostConfig(druidDefaultStyleOptions);
export const druidOverrides_adaptiveCardHostConfig = {
    spacing: {
        padding: druidDefaultStyleOptions.paddingRegular,
        // large: 30 // this is used, for ex, at the horizontalSeparator of adaptiveCards. see [actions.spacing]
    },
    actions: {
        // buttonSpacing: 20,
        // actionAlignment: 'left',
        // actionsOrientation: 'vertical',
        allowTitleToWrap: true,
        
        spacing: "medium"
    },
    fontSizes: {
        default : BotFrameworkDefaultStyleOptions.rootFontSize, // doesn't realy work with rems. falls back to transcriptFontSize.
        // small: 16,
        // default : '1rem', // doesn't realy work with rems. falls back to transcriptFontSize.
        // medium: 16,
        // large: 16,
        // extraLarge: 16
    }
    // fontFamily: 'Druid Regular',
    
};
export const webChatDefaultOptions: WebChatOptions =  {
    instanceID: randomHash(),
    botConnection: null,
    userId: 'anonymous',
    backgroundTrafficEnabled: true,
    authorizationBaseUrl: null, // leave null for baseUrl, set '/' for containing website root url, set 'https://site.com' for full root url
    withCredentials: false,
    withCredentials_authorization: false,
    druidCookieConsentEnabled: false,
    conversationTokenEnabled: true,
    druidWebChatIsOpenedEnabled: true,
    conversationToken_namePrefix: "",
    druidWebChatIsOpened_namePrefix: "",   
    druidCookiesExpirationInDays: 1,
    notificationsEnabled: false, 
    isDisabled: false, // remote disable whole chat (hide)
    otherServicesEndpointIndex: 0,
    _isSecondaryChannel: false,
    searchAssistEnabled: false,
    UI: {
        isVoiceInput: false,
        containerElement: null,
        closeButtonEnabled: false, // controls if containerElement should be hidden on expand
        autoExpandEnabled: false,
        showPoweredBy: false,
        activityWrapperClassEnabled: false,
        accessibilityFeaturesEnabled: false,
        width: 400,
        height: '60vh',
        minWidth: 400,
        bottom: 20,
        top: 'auto',
        chatTitle: 'Webchat',

        restylingsEnabled: false,

        // contextMenuIcon: 'BsMenuUp',
        // contextMenuIcon: 'FaBars',
        position: 'right',
        showUploadButton: null,
        floatRight: 20,
        resizableEnabled: true,
        draggableEnabled: false,

        buttonsPadding: (druidDefaultStyleOptions?.paddingRegular!) / 2,
        buttonsBorderRadius: (druidDefaultStyleOptions?.paddingRegular!) / 2,
        buttonsBorderColor: '$accent', //(druidDefaultStyleOptions?.accent),
        buttonsBackgroundColor: 'white',
        buttonsTextColor: '$accent', //(druidDefaultStyleOptions?.accent),

        activityStatusShowUserName: true,
        activityStatusShowBotName: true,

        v2_minimize_icon: null, // fallback to default icon
        //  ({size}: {size: number}) => {
        //     return `<svg stroke="currentColor" fill="currentColor" width="${size || 'auto'}" height="${size|| 'auto'}" stroke-width="0" viewBox="0 0 352 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>`
        // },
        v2_contextMenu_icon: null, // fallback to default icon
        //  ({size}: {size: number}) => {
        //     return `<svg stroke="currentColor" fill="currentColor" stroke-width="0"  width="${size || 'auto'}" height="${size|| 'auto'}" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>`
        // },
        v2_switch_icon: 'BsArrowLeftRight',
        v2_customButton_toggled_icon: "",
        v2_customButton_off_icon: "",
        v2_containerElement_expand_icon: null,
        v2_containerElement_collapse_icon: null,
        chatBoxBorderRadius: 4,
        chatBoxBorderColor: '#eee',
        chatBoxBorderSize: 1,

        headerTextColor: 'white',
        headerButtonColorOnFocus: '$sendBoxButtonColorOnFocus',
        headerFontSize: '1.3em', // 1.3 larger than fontsize
        headerBottomBorderRadius: 25,
        // headerBorderRadius: (druidDefaultStyleOptions?.paddingRegular!) / 2,
        rootFontSize: BotFrameworkDefaultStyleOptions.rootFontSize,
        transcriptFontSize: '$rootFontSize',
        transcriptLinkColor: '#005A9E',
        sendBoxFontSize: '$rootFontSize',
        lineHeight: '1.25', // normal == 1.2 (browser dependent)
        headerOptions: {
            showHeader: true,
            showChatTitle: true,
            showCloseButton: true,
            showChangeSideButton: true,
            showLanguageSelector: true,
            showIcon: true,
            showAvatar: false,
            showVoiceInputSelector: true,
            showReload: process.env.NODE_ENV === "development",
            showReset: true
          }

        // activityStatusReadColor: '#70b244'
    },
    styleOptions: druidDefaultStyleOptions,
    adaptiveCardsHostConfig: merge.all([
            // there is a problem when initialOptions are provided.
            defaultAdaptiveCardHostConfig,
            druidOverrides_adaptiveCardHostConfig
    ]),
    languages: [],
    localization: druidDefaultLocalization as any,
    conversationHistoryActivities: [],
    designPatterns: {
        nonCarousel_HeroCard_AutoWidth: false,
        nonCarousel_HeroCard_ButtonsOnly_BackgroundTransparent: true,
    },
    // ...Components.Composer.defaultProps,
    ...Composer.defaultProps,
    suggestedActionsAccessKey: false // could be huge bug for accessibility. review AccessKeySinkSurface and AccessKeySinkContext
}

export const restylingsWebchatDefaultOptions: Partial<WebChatOptions> = {
    UI: {
        chatBoxBorderRadius: 15,
        headerTextColorOnHover: 'black',
        headerButtonColorOnFocus: 'black',
        headerBorderRadius: (druidDefaultStyleOptions?.paddingRegular!) / 2,
        restylingsEnabled: true
        // headerOuterPadding: 5,
    },
    styleOptions: {
        accent: '#6bbf00',
        sendBoxHeight: 50,
        bubbleBackground: 'white',
        bubbleFromUserBackground: '#f4f5f7',
        bubbleNubOffset: 'bottom',
        bubbleFromUserNubOffset: 'bottom',
        sendBoxButtonColor: '$accent',
        sendBoxButtonColorOnFocus: '$accent',
        sendBoxButtonColorOnHover: 'white',
        sendBoxButtonShadeBorderRadius: 50,
        // sendBoxButtonShadeColor: 'pink',
        sendBoxButtonShadeColorOnHover: '$accent',
        sendBoxButtonAlignment: 'stretch'
        // sendBoxButtonColorOnHover: 'white',

        // sendBoxButtonColorOnHover: '$accent',
    }
}


export const webChatFullScreenDefaultOptions: WebChatOptions = merge.all<WebChatOptions>([
         webChatDefaultOptions,
         {
            conversationTokenEnabled: false, // overriden by remote config. added only to take notice here.
            UI: {
                resizableEnabled: false,
                headerOptions: {
                    showCloseButton: true,
                    showChangeSideButton: false,
                    showLanguageSelector: true,
                    showAvatar: true
                }
        }}
]);

export enum DruidCookies {
    DruidWebChatIsOpened = "druidWebChatIsOpened",
    DruidConversationToken = "druidConversationToken",
    DruidConversationLanguage= "druidConversationLanguage",
    DruidCookieConsent= "druidCookieConsent",
}

export function druidNormalizeStyleOptions(styleOptions: FullBundleStyleOptions): FullBundleStyleOptions {
    if(!styleOptions) {
        return styleOptions;
    }
    if (styleOptions.suggestedActionBackground !== undefined) {
        styleOptions.suggestedActionBackgroundColor ||= styleOptions?.suggestedActionBackground;
        styleOptions.suggestedActionBackground = undefined;
    }
    if (styleOptions?.suggestedActionDisabledBackground !== undefined) {
        styleOptions.suggestedActionBackgroundColorOnDisabled ||= styleOptions?.suggestedActionDisabledBackground;
        styleOptions.suggestedActionDisabledBackground = undefined;
    }
    if (styleOptions?.suggestedActionDisabledBorderColor !== undefined) {
        styleOptions.suggestedActionBorderColorOnDisabled ||= styleOptions?.suggestedActionDisabledBorderColor;
        styleOptions.suggestedActionDisabledBorderColor = undefined;
    }
    if (styleOptions?.suggestedActionDisabledTextColor !== undefined) {
        styleOptions.suggestedActionTextColorOnDisabled ||= styleOptions?.suggestedActionDisabledTextColor;
        styleOptions.suggestedActionDisabledTextColor = undefined;
    }

    return styleOptions;
}